<template>
  <section id="liquidity">
    <b-overlay :show="showLiqTrend" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Liquidity trend
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-liquidity" />
          </h4>
          <b-popover target="popover-liquidity" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="DollarSignIcon" />
              </b-input-group-prepend>
              <b-form-input v-model="bankAccountStatement" placeholder="Bank Account Statement" type="number"
                @keyup.enter="changeBankAccountStatement" />
            </b-input-group>
          </b-card-text>
        </b-card-header>
        <ECharts ref="liqTrendChart" :options="liqTrendChart" style="width: 100%; height: 500px;" />
        <b-card no-body>
          <b-table striped hover responsive class="position-relative" :current-page="liqTrendTable.currentPage"
            :per-page="10" :items="liqTrendTable.items" :fields="liqTrendTable.fields"
            :sort-by.sync="liqTrendTable.sortBy" :sort-desc.sync="liqTrendTable.sortDesc"
            :sort-direction="liqTrendTable.sortDirection" :filter="liqTrendTable.filter"
            :filter-included-fields="liqTrendTable.filterOn">
          </b-table>
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination v-model="liqTrendTable.currentPage" :total-rows="liqTrendTable.totalRows" first-number
                last-number prev-class="prev-item" next-class="next-item" class="mb-0">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-card>
      <b-row>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="liq7DaysTrend >= 0" class="mb-25 font-weight-bolder text-success">
                  +{{ liq7DaysTrend | formatNumber }}
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-danger">
                  {{ liq7DaysTrend | formatNumber }}
                </h2>
                <span>Next 7 days</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="liq14DaysTrend >= 0" class="mb-25 font-weight-bolder text-success">
                  +{{ liq14DaysTrend | formatNumber }}
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-danger">
                  {{ liq14DaysTrend | formatNumber }}
                </h2>
                <span>Next 14 days</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="liq21DaysTrend >= 0" class="mb-25 font-weight-bolder text-success">
                  +{{ liq21DaysTrend | formatNumber }}
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-danger">
                  {{ liq21DaysTrend | formatNumber }}
                </h2>
                <span>Next 21 days</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <b-row>
      <b-col xl="6" md="6">
        <b-overlay :show="showInPayments" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Expected incoming payments
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-expected-incoming-payments" />
              </h4>
              <b-popover target="popover-expected-incoming-payments" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <b-table striped hover responsive class="position-relative" :current-page="inPaymentsTable.currentPage"
              :items="inPaymentsTable.items" :fields="inPaymentsTable.fields" :sort-by.sync="inPaymentsTable.sortBy"
              :sort-desc.sync="inPaymentsTable.sortDesc" :sort-direction="inPaymentsTable.sortDirection"
              :filter="inPaymentsTable.filter" :filter-included-fields="inPaymentsTable.filterOn"
              @sort-changed="sortChangedInPayments" />
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="inPaymentsTable.currentPage" :total-rows="inPaymentsTable.totalRows" first-number
                  last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                  @change="handleInPaymentsTablePageChange">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col xl="6" md="6">
        <b-overlay :show="showOutPayments" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Expected outgoing payments
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-expected-outgoing-payments" />
              </h4>
              <b-popover target="popover-expected-outgoing-payments" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
              <b-card-text class="font-medium-5 mb-0">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
                  @click="openPermanentPaymentsDetails()">
                  Permanent Payments
                </b-button>
              </b-card-text>
            </b-card-header>
            <b-table striped hover responsive class="position-relative" :current-page="outPaymentsTable.currentPage"
              :items="outPaymentsTable.items" :fields="outPaymentsTable.fields" :sort-by.sync="outPaymentsTable.sortBy"
              :sort-desc.sync="outPaymentsTable.sortDesc" :sort-direction="outPaymentsTable.sortDirection"
              :filter="outPaymentsTable.filter" :filter-included-fields="outPaymentsTable.filterOn"
              @sort-changed="sortChangedOutPayments" />
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="outPaymentsTable.currentPage" :total-rows="outPaymentsTable.totalRows"
                  first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                  @change="handleOutPaymentsTablePageChange">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-modal ref="permanent-payments-modal" size="xl" title="Permanent payments" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-table striped hover responsive class="position-relative" :current-page="permanentPaymentsTable.currentPage"
        :items="permanentPaymentsTable.items" :fields="permanentPaymentsTable.fields"
        :sort-by.sync="permanentPaymentsTable.sortBy" :sort-desc.sync="permanentPaymentsTable.sortDesc"
        :sort-direction="permanentPaymentsTable.sortDirection" :filter="permanentPaymentsTable.filter"
        :filter-included-fields="permanentPaymentsTable.filterOn" @row-clicked="permanentPaymentsRowClicked">
        <template #cell(is_avg_payment)="data">
          <div v-if="data.item.is_avg_payment">
            <feather-icon icon="CheckCircleIcon" />
          </div>
          <div v-else>
            <feather-icon icon="XCircleIcon" />
          </div>
        </template>
        <template #cell(delete)="data">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat" class="btn-icon"
            @click="openDeleteConfirm(data.item.id)">
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- pagination -->
        <div>
          <b-pagination v-model="permanentPaymentsTable.currentPage" :total-rows="permanentPaymentsTable.totalRows"
            first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
            @change="handlePermanentPaymentsPageChange">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-row>
        <b-col xl="8" md="8">
        </b-col>
        <b-col xl="4" md="4">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
            @click="addPermanentPaymentClicked">
            Add Permanent Payment
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="modal-permanent-payment-submit" cancel-variant="outline-secondary" ok-title="Submit"
      cancel-title="Close" centered :title="permanentPaymentID ? 'Update' : 'Add'" :no-close-on-backdrop="true"
      @ok="handlePermanentPaymentSubmit" @hidden="resetPermanentPaymentModal">
      <b-form @submit.prevent>
        <b-form-group label="Name" label-for="permanentPaymentName">
          <b-form-input id="permanentPaymentName" v-model="permanentPaymentName" />
        </b-form-group>
        <b-form-group label="Recurring Type" label-for="permanentPaymentType">
          <v-select id="permanentPaymentType" v-model="permanentPaymentType" label="title"
            :options="permanentPaymentTypes" />
        </b-form-group>
        <b-form-group label="Value" label-for="permanentPaymentValue">
          <b-form-input id="permanentPaymentValue" v-model="permanentPaymentValue" type="number" />
        </b-form-group>
        <b-form-group label="Start Date Period" label-for="permanentPaymentStartDate">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr id="permanentPaymentStartDate" v-model="permanentPaymentStartDate" class="form-control" />
          </b-input-group>
        </b-form-group>
        <b-form-group label="End Date Period" label-for="permanentPaymentEndDate">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr id="permanentPaymentEndDate" v-model="permanentPaymentEndDate" class="form-control" />
          </b-input-group>
        </b-form-group>
        <b-form-group label="Payment Term" label-for="permanentPaymentDate">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr id="permanentPaymentDate" v-model="permanentPaymentDate" class="form-control" />
          </b-input-group>
        </b-form-group>
        <b-form-group label="Avg. Payment" label-for="is-avg-payment">
          <b-form-checkbox id="is-avg-payment" v-model="isAvgPayment">
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <h4 class="mb-0">
          Liquidity development
          <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
            id="popover-liquidity-development" />
        </h4>
        <b-popover target="popover-liquidity-development" triggers="hover" placement="bottom">
          <span>No data</span>
        </b-popover>
      </b-card-header>
      <ECharts ref="liquidityDevelopmentChart" :options="liquidityDevelopmentChart"
        style="width: 100%; height: 500px;" />
    </b-card>
    <b-overlay :show="showOrdersCancellationRate" spinner-variant="primary" variant="transparent" blur="3px"
      rounded="sm">
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Orders count
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-orders-count-development" />
              </h4>
              <b-popover target="popover-orders-count-development" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="ordersCountDevelopmentChart" :options="ordersCountDevelopmentChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-table striped hover responsive class="position-relative" :current-page="ordersCountTable.currentPage"
              :per-page="10" :items="ordersCountTable.items" :fields="ordersCountTable.fields"
              :sort-by.sync="ordersCountTable.sortBy" :sort-desc.sync="ordersCountTable.sortDesc"
              :sort-direction="ordersCountTable.sortDirection" :filter="ordersCountTable.filter"
              :filter-included-fields="ordersCountTable.filterOn" />
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="ordersCountTable.currentPage" :total-rows="ordersCountTable.totalRows"
                  first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Cancellation rate
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-cancellation-rate-development" />
              </h4>
              <b-popover target="popover-cancellation-rate-development" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="cancellationRateDevelopmentChart" :options="cancellationRateDevelopmentChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Cancellation rate by order value
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-cancellation-rate-order-value-development" />
              </h4>
              <b-popover target="popover-cancellation-rate-order-value-development" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="cancellationRateOrderValueDevelopmentChart"
              :options="cancellationRateOrderValueDevelopmentChart" style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Top cancelled products
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-cancelled-productst" />
              </h4>
              <b-popover target="popover-cancelled-productst" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <b-table striped hover responsive class="position-relative"
              :current-page="canceledProductsTable.currentPage" :per-page="10" :items="canceledProductsTable.items"
              :fields="canceledProductsTable.fields" :sort-by.sync="canceledProductsTable.sortBy"
              :sort-desc.sync="canceledProductsTable.sortDesc" :sort-direction="canceledProductsTable.sortDirection"
              :filter="canceledProductsTable.filter" :filter-included-fields="canceledProductsTable.filterOn">
            </b-table>
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="canceledProductsTable.currentPage" :total-rows="canceledProductsTable.totalRows"
                  first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. order value of cancelled orders
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-avg-cancelled-order-value-development" />
              </h4>
              <b-popover target="popover-avg-cancelled-order-value-development" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="avgCancelledOrderValueDevelopmentChart" :options="avgCancelledOrderValueDevelopmentChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Cancellation rate by customer group
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-cancellation-rate-customer-group" />
              </h4>
              <b-popover target="popover-cancellation-rate-customer-group" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="cancellationRateCustomerGroupDevelopmentChart"
              :options="cancellationRateCustomerGroupDevelopmentChart" style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Days from order create to payment
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-payment-days-distribution" />
              </h4>
              <b-popover target="popover-payment-days-distribution" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="paymentDaysDistributionChart" :options="paymentDaysDistributionChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Payment methods distribution
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-payment-methods-distribution" />
              </h4>
              <b-popover target="popover-payment-methods-distribution" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="paymentMethodsDistributionChart" :options="paymentMethodsDistributionChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="8" md="8">
          <b-card no-body>
            <b-table striped hover responsive class="position-relative"
              :current-page="paymentMethodsDistributionTable.currentPage" :per-page="10"
              :items="paymentMethodsDistributionTable.items" :fields="paymentMethodsDistributionTable.fields"
              :sort-by.sync="paymentMethodsDistributionTable.sortBy"
              :sort-desc.sync="paymentMethodsDistributionTable.sortDesc"
              :sort-direction="paymentMethodsDistributionTable.sortDirection"
              :filter="paymentMethodsDistributionTable.filter"
              :filter-included-fields="paymentMethodsDistributionTable.filterOn">
            </b-table>
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="paymentMethodsDistributionTable.currentPage"
                  :total-rows="paymentMethodsDistributionTable.totalRows" first-number last-number
                  prev-class="prev-item" next-class="next-item" class="mb-0">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BCard,
  BOverlay,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormCheckbox,
  BButton,
  BModal,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import ECharts from 'vue-echarts';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BOverlay,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormCheckbox,
    BButton,
    BModal,
    vSelect,
    flatPickr,
    ToastificationContent,
    ECharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showLiqTrend: true,
      showLiqDev: true,
      showInPayments: true,
      showOutPayments: true,
      showOrdersCancellationRate: true,
      bankAccountStatement: 0,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      liq7DaysTrend: 0.0,
      liq14DaysTrend: 0.0,
      liq21DaysTrend: 0.0,
      permanentPaymentID: '',
      permanentPaymentTypes: ['once', 'monthly', 'quarterly', 'yearly'],
      permanentPaymentName: '',
      permanentPaymentValue: 0.0,
      permanentPaymentStartDate: '',
      permanentPaymentEndDate: '',
      permanentPaymentDate: '',
      permanentPaymentType: '',
      isAvgPayment: false,
      queryParamsLiqTrend: {},
      queryParams: {},
      queryParamsInPayments: {},
      queryParamsOutPayments: {},
      queryParamsPermanentPayments: {},
      liqTrendChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      liqTrendTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'payment_term',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          {
            key: 'value_in',
            label: 'value in',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'paid_in',
            label: 'paid in',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'value_out',
            label: 'value out',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'paid_out',
            label: 'paid out',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'open_orders_value',
            label: 'open orders',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'paid_open_orders',
            label: 'paid open orders',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'avg_cancelled_order_value',
            label: 'avg cancelled order value per day',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'value',
            label: 'total value',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment_term',
            label: 'date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      liquidityDevelopmentChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      inPaymentsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'payment_term',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'order_no', label: 'order no', sortable: true },
          { key: 'invoice_no', label: 'invoice no', sortable: true },
          { key: 'company_name', label: 'company name', sortable: true },
          {
            key: 'total_amount',
            label: 'total amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment',
            label: 'payment',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'will_receive',
            label: 'will receive',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment_term',
            label: 'payment term',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'shipped_date',
            label: 'shipped date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      outPaymentsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'payment_term',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'invoice_no', label: 'invoice no', sortable: true },
          { key: 'company_name', label: 'company name', sortable: true },
          {
            key: 'total_amount',
            label: 'total amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment',
            label: 'payment',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'need_to_pay',
            label: 'need to pay',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment_term',
            label: 'payment term',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'received_date',
            label: 'received date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      permanentPaymentsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'name',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'value',
            label: 'total amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'recurring_type', label: 'recurring type', sortable: true },
          {
            key: 'start_date',
            label: 'start date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'end_date',
            label: 'end date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'payment_date',
            label: 'payment term',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'is_avg_payment', label: 'avg. payment', sortable: true },
          { key: 'delete', label: '' },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      ordersCountDevelopmentChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersCountTable: {
        totalRows: 1,
        currentPage: 1,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'orders_count', label: 'orders count', sortable: true },
          { key: 'canceled_orders_count', label: 'canceled orders count', sortable: true },
          { key: 'cancellation_rate', label: 'cancellation rate', sortable: true },
          { key: 'month_year', label: 'date', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      cancellationRateDevelopmentChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      cancellationRateOrderValueDevelopmentChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      canceledProductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'qty_cancelled',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'qty_cancelled', label: 'qty cancelled', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      avgCancelledOrderValueDevelopmentChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      cancellationRateCustomerGroupDevelopmentChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      paymentMethodsDistributionChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')}<br/>`;
          }
        },
        series: [
          {
            name: 'Orders count',
            type: 'pie',
            radius: ['0%', '95%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
      paymentMethodsDistributionTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'orders_count',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'payment_method', label: 'payment method', sortable: true },
          { key: 'orders_count', label: 'orders count', sortable: true },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      paymentDaysDistributionChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParams.from_date = dateRange[0];
    this.queryParams.to_date = dateRange[1];

    try {
      await this.getLiqTrend();
      await this.getLiquidityDevelopment();
      await this.getInPayments();
      await this.getOutPayments();

      this.showOrdersCancellationRate = true;
      await this.getOrdersCancellationRate();
      await this.getCanceledProducts();
      await this.getCancellationRateByCustomerGroup();
      await this.getPaymentMethodsDistribution();
      await this.getPaymentDaysDistribution();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getLiqTrend() {
      this.showLiqTrend = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/liquidity-trend/`, this.queryParamsLiqTrend);
        const results = response.data.results;

        this.liqTrendChart.xAxis.data = results.map(item => item.payment_term);
        this.liqTrendChart.series[0] = {
          name: 'Value',
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top',
            distance: 5,
            align: 'left',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          },
          data: results.map(item => (item.value + parseFloat(this.bankAccountStatement)).toFixed(2)),
        };

        this.liqTrendTable.items = results;
        this.liqTrendTable.totalRows = results.length * 2;

        const today = new Date();
        const next7Date = new Date().setDate(today.getDate() + 7);
        const next14Date = new Date().setDate(today.getDate() + 14);
        const next21Date = new Date().setDate(today.getDate() + 21);
        let liq7DaysTrend = 0.0;
        let liq14DaysTrend = 0.0;
        let liq21DaysTrend = 0.0;
        results.forEach(item => {
          const currDate = new Date(item.payment_term);
          if (currDate <= next7Date) {
            liq7DaysTrend += parseFloat(item.value);
          }
          if (currDate <= next14Date) {
            liq14DaysTrend += parseFloat(item.value);
          }
          if (currDate <= next21Date) {
            liq21DaysTrend += parseFloat(item.value);
          }
        })
        liq7DaysTrend += parseFloat(this.bankAccountStatement);
        liq14DaysTrend += parseFloat(this.bankAccountStatement);
        liq21DaysTrend += parseFloat(this.bankAccountStatement);
        this.liq7DaysTrend = liq7DaysTrend;
        this.liq14DaysTrend = liq14DaysTrend;
        this.liq21DaysTrend = liq21DaysTrend;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showLiqTrend = false;
      }
    },
    async getLiquidityDevelopment() {
      this.showLiqDev = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/liquidity-development/`, this.queryParams);
        this.liquidityDevelopmentChart.xAxis.data = response.data.results.map(item => item.month_year);
        this.liquidityDevelopmentChart.series[0] = {
          name: 'Value',
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top',
            distance: 5,
            align: 'left',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          },
          data: response.data.results.map(item => item.value),
          markLine: {
            symbol: 'none',
            data: [{ type: 'average', name: 'Avg' }]
          }
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showLiqDev = false;
      }
    },
    async getInPayments() {
      this.showInPayments = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/exp-in-payments/`, this.queryParamsInPayments);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.inPaymentsTable.items = results;
          this.inPaymentsTable.totalRows = results[0].count * 2;
        }
        else {
          this.inPaymentsTable.items = [];
          this.inPaymentsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showInPayments = false;
      }
    },
    async getOutPayments() {
      this.showOutPayments = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/exp-out-payments/`, this.queryParamsOutPayments);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.outPaymentsTable.items = results;
          this.outPaymentsTable.totalRows = results[0].count * 2;
        }
        else {
          this.outPaymentsTable.items = [];
          this.outPaymentsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOutPayments = false;
      }
    },
    async getPermanentPayments() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/permanent-payments/`, this.queryParamsPermanentPayments);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.permanentPaymentsTable.items = results;
          this.permanentPaymentsTable.totalRows = results.length;
        }
        else {
          this.permanentPaymentsTable.items = [];
          this.permanentPaymentsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getOrdersCancellationRate() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-cancellation-rate/`, this.queryParams);
        const results = response.data.results;
        const monthYearLabels = results.map(item => item.month_year);

        this.ordersCountDevelopmentChart.xAxis.data = monthYearLabels;
        this.ordersCountDevelopmentChart.series[0] = {
          name: 'Canceled orders count',
          type: 'line',
          label: {
            show: true,
            position: 'top',
            distance: 5,
            align: 'left',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          },
          data: results.map(item => item.canceled_orders_count),
          markLine: {
            symbol: 'none',
            data: [{ type: 'average', name: 'Avg' }]
          }
        };
        this.ordersCountDevelopmentChart.series[1] = {
          name: 'Orders count',
          type: 'line',
          label: {
            show: true,
            position: 'top',
            distance: 5,
            align: 'left',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          },
          data: results.map(item => item.orders_count),
          markLine: {
            symbol: 'none',
            data: [{ type: 'average', name: 'Avg' }]
          }
        };

        this.ordersCountTable.items = results;
        this.ordersCountTable.totalRows = results.length * 2;

        this.cancellationRateDevelopmentChart.xAxis.data = monthYearLabels;
        this.cancellationRateDevelopmentChart.series[0] = {
          name: "Cancellation rate",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.cancellation_rate),
          markLine: {
            symbol: 'none',
            data: [{ type: 'average', name: 'Avg' }]
          }
        };

        this.cancellationRateOrderValueDevelopmentChart.xAxis.data = monthYearLabels;
        this.cancellationRateOrderValueDevelopmentChart.series[0] = {
          name: "Cancellation rate",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.cancellation_rate_order_value),
          markLine: {
            symbol: 'none',
            data: [{ type: 'average', name: 'Avg' }]
          }
        };

        this.avgCancelledOrderValueDevelopmentChart.xAxis.data = monthYearLabels;
        this.avgCancelledOrderValueDevelopmentChart.series[0] = {
          name: "Avg. order value",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.canceled_avg_order_value),
          markLine: {
            symbol: 'none',
            data: [{ type: 'average', name: 'Avg' }]
          }
        };

      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getCanceledProducts() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/canceled-products-count/`, this.queryParams);
        const results = response.data.results;

        if (Array.isArray(results) && results.length > 0) {
          this.canceledProductsTable.items = results;
          this.canceledProductsTable.totalRows = results.length * 2;
        }
        else {
          this.canceledProductsTable.items = [];
          this.canceledProductsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getCancellationRateByCustomerGroup() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/cancellation-rate-by-customer-group/`, this.queryParams);
        const results = response.data.results;
        // 1. Get unique month_year values
        const monthYearLabels = [...new Set(results.map(item => item.month_year))];

        // 2. Group results by customer_group
        const groupedByCustomerGroup = results.reduce((acc, curr) => {
          const key = curr.customer_group;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(curr);
          return acc;
        }, {});

        // 3. Create a new object with only the groups we care about
        const groupsToInclude = ["A-Kunde", "B-Kunde", "C-Kunde"];
        const newGroupedByCustomerGroup = {};
        groupsToInclude.forEach(group => {
          newGroupedByCustomerGroup[group] = groupedByCustomerGroup[group] || [];
        });

        // 4. Build the datasets for each customer group
        let cancellationRateByCustomerGroupDatasets = [];
        for (const key in newGroupedByCustomerGroup) {
          const groupData = newGroupedByCustomerGroup[key];

          // Create a lookup mapping from month_year to cancellation_rate
          // (Assuming one value per month; if there are multiples, adjust as needed)
          const lookup = groupData.reduce((obj, item) => {
            obj[item.month_year] = item.cancellation_rate;
            return obj;
          }, {});

          // For each distinct month-year label, get the corresponding cancellation_rate or 0.0 if missing.
          const cancellationRateData = monthYearLabels.map(label => (lookup[label] !== undefined ? lookup[label] : 0.0));

          cancellationRateByCustomerGroupDatasets.push({
            name: key,
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: cancellationRateData,
          });
        }

        // Finally, assign the xAxis data and series to your chart configuration
        this.cancellationRateCustomerGroupDevelopmentChart.xAxis.data = monthYearLabels;
        this.cancellationRateCustomerGroupDevelopmentChart.series = cancellationRateByCustomerGroupDatasets;

      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getPaymentMethodsDistribution() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/payment-methods-distribution/`, this.queryParams);
        const results = response.data.results;

        this.paymentMethodsDistributionChart.series[0].data = results.map(item => {
          return {
            value: item.orders_count,
            name: item.payment_method,
          };
        });

        this.paymentMethodsDistributionTable.items = results;
        this.paymentMethodsDistributionTable.totalRows = results.length * 2;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getPaymentDaysDistribution() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/payment-days-distribution/`, this.queryParams);
        const results = response.data.results;
        const monthYearLabels = results.map(item => item.month_year);

        this.paymentDaysDistributionChart.xAxis.data = monthYearLabels;
        this.paymentDaysDistributionChart.series[0] = {
          name: "Avg. value",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.avg_days_diff),
          markLine: {
            symbol: 'none',
            data: [{ type: 'average', name: 'Avg' }]
          }
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
      finally {
        this.showOrdersCancellationRate = false;
      }
    },
    async sortChangedInPayments(value) {
      if (value.sortDesc === true) {
        this.queryParamsInPayments.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsInPayments.ordering = value.sortBy;
      }
      await this.getInPayments();
    },
    async sortChangedOutPayments(value) {
      if (value.sortDesc === true) {
        this.queryParamsOutPayments.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsOutPayments.ordering = value.sortBy;
      }
      await this.getOutPayments();
    },
    async handleInPaymentsTablePageChange(value) {
      this.queryParamsInPayments.page = value;
      await this.getInPayments();
    },
    async handleOutPaymentsTablePageChange(value) {
      this.queryParamsOutPayments.page = value;
      await this.getOutPayments();
    },
    async handlePermanentPaymentsPageChange(value) {
      this.queryParamsPermanentPayments.page = value;
      await this.getPermanentPayments();
    },
    addPermanentPaymentClicked() {
      this.$refs['modal-permanent-payment-submit'].show();
    },
    permanentPaymentsRowClicked(row) {
      this.permanentPaymentID = row.id;
      this.permanentPaymentName = row.name;
      this.permanentPaymentValue = row.value;
      this.permanentPaymentStartDate = row.start_date;
      this.permanentPaymentEndDate = row.end_date;
      this.permanentPaymentDate = row.payment_date;
      this.permanentPaymentType = row.recurring_type;
      this.isAvgPayment = row.is_avg_payment;
      this.$refs['modal-permanent-payment-submit'].show();
    },
    resetPermanentPaymentModal() {
      this.permanentPaymentID = '';
      this.permanentPaymentName = '';
      this.permanentPaymentValue = 0.0;
      this.permanentPaymentStartDate = '';
      this.permanentPaymentEndDate = '';
      this.permanentPaymentDate = '';
      this.permanentPaymentType = '';
      this.isAvgPayment = false;
    },
    async handlePermanentPaymentSubmit() {
      const params = {
        name: this.permanentPaymentName,
        value: this.permanentPaymentValue,
        start_date: this.permanentPaymentStartDate,
        end_date: this.permanentPaymentEndDate,
        payment_date: this.permanentPaymentDate,
        recurring_type: this.permanentPaymentType,
        is_avg_payment: this.isAvgPayment,
      }

      if (this.permanentPaymentID
        && this.planNumbersName !== ''
        && this.permanentPaymentValue !== 0.0
        && this.permanentPaymentStartDate
        && this.permanentPaymentEndDate
        && this.permanentPaymentDate
        && this.permanentPaymentType !== '') {
        await axios
          .put(`${process.env.VUE_APP_ROOT_API}/permanent-payments/${this.permanentPaymentID}/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Permanent payment has been updated');
            await this.getPermanentPayments();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else if (this.planNumbersName !== ''
        && this.permanentPaymentValue !== 0.0
        && this.permanentPaymentStartDate
        && this.permanentPaymentEndDate
        && this.permanentPaymentDate
        && this.permanentPaymentType !== '') {
        await axios
          .post(`${process.env.VUE_APP_ROOT_API}/permanent-payments/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Permanent payment created');
            await this.getPermanentPayments();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else { this.showToast('danger', 'Error!') }
    },
    openDeleteConfirm(id) {
      this.permanentPaymentID = id;
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await axios
            .delete(`${process.env.VUE_APP_ROOT_API}/permanent-payments/${this.permanentPaymentID}/`, {
              headers: {
                Authorization: `JWT ${this.$store.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(async response => {
              console.log(response);
              this.showToast('info', 'Permanent payment has been deleted');
              await this.getPermanentPayments();
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
        this.permanentPaymentID = '';
      })
    },
    async changeBankAccountStatement() {
      // this.showLiqTrend = true
      // this.liqTrendChart.data.datasets[0].data = this.liqTrendChart.data.datasets[0].data.map(
      //   item => item + parseFloat(this.bankAccountStatement),
      // )
      await this.getLiqTrend();
      // this.showLiqTrend = false
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];
        await this.getLiquidityDevelopment();

        this.showOrdersCancellationRate = true;
        await this.getOrdersCancellationRate();
        await this.getCanceledProducts();
        await this.getCancellationRateByCustomerGroup();
        await this.getPaymentMethodsDistribution();
        await this.getPaymentDaysDistribution();
        this.oldDateRange = this.dateRange;

        this.$nextTick(() => {
          this.$refs.liquidityDevelopmentChart.refresh();
          this.$refs.ordersCountDevelopmentChart.refresh();
          this.$refs.cancellationRateDevelopmentChart.refresh();
          this.$refs.cancellationRateOrderValueDevelopmentChart.refresh();
          this.$refs.cancellationRateCustomerGroupDevelopmentChart.refresh();
          this.$refs.paymentMethodsDistributionChart.refresh();
          this.$refs.paymentDaysDistributionChart.refresh();
        });
      }
    },
    async openPermanentPaymentsDetails() {
      await this.getPermanentPayments();
      this.$refs['permanent-payments-modal'].show();
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
